
import { AppModule } from "@/store/app";
import { ArticleCollection, Category } from "@planetadeleste/vue-mc-goodnews";
import { Vue, Component } from "vue-property-decorator";
import InterviewItem from "@/modules/interviews/components/InterviewItem.vue";

@Component({
  components: { InterviewItem },
})
export default class InterviewsListing extends Vue {
  obCollection = new ArticleCollection();

  mounted(): void {
    this.$nextTick(this.load);
  }

  async load(): Promise<void> {
    if (!this.category) {
      return;
    }

    this.obCollection.filterBy({ category: this.category.id, active: true });
    await this.obCollection.fetch();
  }

  get category(): Category | undefined {
    return AppModule.blogCategories.find({ slug: "youtube" });
  }
}
